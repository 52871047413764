.ant-layout-header {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  background: transparent;
  margin: 0;
  position: relative;
  margin-left: -75px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .ant-layout-header {
    display: none;
  }
}

.social-links {
  display: flex;
  gap: 10px;
  top: -20px; /* Adjust the top distance as needed */
  right: -20px; /* Adjust the right distance as needed */
  position: absolute;
  margin-right: -63px;
}

.social-links a {
  text-decoration: none;
  color: #333; /* Set your desired color */
  font-weight: bold;
}

.ant-layout {
  margin: auto;
  padding: 0;
  max-width: 1024px;
  background: transparent !important;
}

.ant-layout-footer {
  background: transparent !important;
}

.title-bg {
  background: #0f3797;
  color: #fff !important;
  font-size: 24px !important;
}

.title-right-bg {
  background: #28cf30 !important;
}

.left-card {
  border-radius: 0px;
}
.left-card .ant-card-head {
  border-radius: 0px;
  border-bottom: 0px;
  background-color: #0f3797;
  color: yellow;
  min-height: 40px;
  font-size: 25px;
  padding-left: 10px;
  text-decoration: underline;
  text-decoration-skip-ink: auto; /* Ensure underline goes through all letters */
}
.left-card .ant-card-body {
  padding: 15px 50px 15px;
  background-color: lightyellow;
}


.custom-row {
  width: 99.9%;  /* Adjust as needed */
  background-color: lightgray;
  margin: 0;
  padding-bottom: 0;
  padding-left: -2;
  font-size: 10px;
  border: 1px darkgray;
  border-radius: 0px;
  border-bottom: 0px;
  margin-left: 12px;
  position: relative;
  left: 6px;  /* This will move it 20px to the right, effectively reducing the width on the left side */
  
}

@media (min-width: 768px) {
  .custom-row {
    margin-bottom: 12px;
  }
}

.custom-row .sign-in-link {
  color: blue; /* You can adjust the color as needed */
  text-decoration: underline;
  cursor: pointer;
}

.right-aligned-col {
  text-align: right;
}

.right-card {
  border-radius: 0px;
}
.right-card .ant-card-head {
  border-radius: 0px;
  border-bottom: 0px;
  background-color: #28cf30;
  color: #fff !important;
  min-height: 40px;
  font-size: 25px;
  padding-left: 10px;
}
.right-card .ant-card-body {
  padding: 15px 24px 15px;
}

.link {
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
}

#root {



}

@media (max-width: 768px) {
  #root {
    margin-top: 0; /* Remove the margin-top on smaller screens */
  }
}
#my-card {
  background-color: #0f3797;
  border-radius: 0px;
  border-bottom: 0px;
}

@media (max-width: 768px) {  /* Choose the mobile breakpoint you prefer */
  #my-card {
    display: none;
  }
}
#text1 {
  color: yellow;
  font-weight: bolder;
  font-size: 50px;
 
}

#text2 {
  color: white;
  font-size: 15px;
}

#title1 {
  font-weight: bold;
  font-size: 15px;
  white-space: nowrap;
}/*# sourceMappingURL=main.css.map */





.mobile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}


/* Default styling for links */
.social-links a {
  text-decoration: none; /* Remove the default underline */
  color: blue; /* Set the text color to blue */
}

/* Add underline on hover */
.social-links a:hover {
  text-decoration: underline;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .social-links {
    text-align: center; /* Center align links on mobile */
  }

  /* Adjust link styles for mobile */
  .social-links a {
    display: block; /* Make links block-level for easier tapping on mobile */
    margin-bottom: 10px; /* Add some spacing between links */
  }
}
.blog-post-container {
  background-color: #f0f0f0; /* Set your desired background color */
  padding: 20px; /* Add padding for spacing */
  display: flex;
  justify-content: left; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Make the container cover the entire viewport height */
}


/* Add this CSS rule to your stylesheet */
@media (max-width: 768px) {
  .mobile-hidden {
    display: none;
  } 
} 


.web-image {
  /* Your existing styles for web here */
  max-width: 200px;
  height: 85px;
}

.mobile-image {
  /* Your styles for mobile here */
  max-width: 200px !important;
  height: 230px !important;
}


@media only screen and (max-width: 768px) {
  /* Debug here */
}

.mobile-button {
  display: none;  /* Hide by default */
}

@media (max-width: 768px) {  /* Adjust breakpoint as needed */
  .mobile-button {
    display: inline-block;  /* Show only on mobile */
  }
}
.mobile-button-container {
  display: flex;
  justify-content: space-between;

}

@media (max-width: 768px) {
  .mobile-button {
    display: inline-block;
    flex: 1; /* Make each button take up equal space */
    border-radius: 0;
    border-color: black;
    background-color: #0f3797;
    margin-bottom: -2;
    color: yellow;
    font-weight: bolder;
  }
}
/* Mobile-specific CSS */
@media only screen and (max-width: 768px) {
  .BlogPostContainer--mobile-fullscreen {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }
}


.kMCTxm {
  margin: -51px;
}

.custom-modal-class .ant-modal {
  width: 50% !important; /* Overriding Ant Design's styles */
  top: 100%;
}


@media screen and (max-width: 768px) {
  .custom-modal-class .ant-modal {
      width: 100% !important;
      height: 100vh !important;
      top: 0;
      padding: 0;
      margin-bottom: 20px;
  }
}